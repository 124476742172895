import { useState } from 'react'
import { useMapStore } from '../../../store'
import { Link } from 'react-router-dom'
import styles from './GalleryModal.module.css'

export const Gallerymodal = (props: {
  selectedSynagogueId: string
}) => {
  const synagoguesList = useMapStore((state) => state.synagoguesList)
  const selectedSynagogue = synagoguesList.filter((item) => item.id === parseInt(props.selectedSynagogueId))
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const handleBack = () => {
    if (currentImageIndex - 1 < 0) {
      setCurrentImageIndex(selectedSynagogue[0].gallery.length - 1)
    } else {
      setCurrentImageIndex(currentImageIndex - 1)
    }
  }

  const handleForward = () => {
    if (currentImageIndex + 1 >= selectedSynagogue[0].gallery.length) {
      setCurrentImageIndex(0)
    } else {
      setCurrentImageIndex(currentImageIndex + 1)
    }
  }

  return (<>
      <div className={styles.overlay} />
      <div className={styles.gallery}>
        <button className={styles.nav__btn} type='button' onClick={handleBack}>
          <img
            alt="Назад"
            src="/icons/light-arrow.svg"
          />
        </button>

        {selectedSynagogue && selectedSynagogue[0].gallery.length > 0 && <>
          {selectedSynagogue[0].gallery.map((item: string) => {
            if (selectedSynagogue[0].gallery.indexOf(item) === currentImageIndex) {
                return <img
                alt="Фото синагоги"
                className={styles.photo}
                src={item}
              />
            } else {
              return null
            }
          })}
        </>}

        <button className={styles.nav__btn_rotated} type='button' onClick={handleForward}>
          <img
            alt="Вперёд"
            src="/icons/light-arrow.svg"
          />
        </button>
      </div>

      <Link
        className={styles.close__link}
        to={'/map'}
      >
        <img alt="Закрыть" src="/icons/cross.svg" />
        закрыть
      </Link>
    </>
  )
}