import ReactDOM from 'react-dom/client'
import { App } from './app/App'
import { Map } from './pages/Map'
import { JSONEditor } from './pages/JSONEditor'
import { Docs } from './pages/Docs'
import { NoEnternet } from './pages/NoEnternet'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/map" element={<Map />} />
      <Route path="/editor" element={<JSONEditor />} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/no-internet" element={<NoEnternet />} />
    </Routes>
  </BrowserRouter>
);