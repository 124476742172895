import styles from './Statictext.module.css'

export const StaticText = (props: {
	isRussian?: boolean
	isEnglish?: boolean
	onClick?: () => void
}) => {
  return (
    <div className={styles.wrapper_scrollable} onClick={props.onClick}>
			<div className={styles.gradient__overlay} />
			{props.isRussian && <div className={styles.wrapper_static}>
				<h1 className={styles.chabad__title}>Бейт Хабад</h1>

				<p className={styles.paragraph}>
					Бейт Хабад (в переводе с иврита ‒ Дом ХаБаДа) ‒ это центр распространения особого направления хасидского иудаизма – Хабада.
					Слово «ХаБаД» составлено из первых букв нескольких слов на иврите. Буквой «Хэт» начинается слово «хохма»,
					что в переводе с иврита означает мудрость. С буквы «Бэт» начинается слово «бина» ‒ понимание.
					Буква «Далет» открывает слово «даат» ‒ знание.
				</p>

				<p className={styles.paragraph}>
					Дом Хабада возглавляет шалиах (в переводе с иврита ‒ посланник) Хабада.
					Первые дома Хабада появились в США, во второй половине XX века.
					Последний любавический ребе Менахем Мендель Шнеерсон отправил своего посланника,
					раввина Шломо Кунина в Лос-Анджелес в 1965 году. Первый Дом Хабада был открыт
					в марте 1969 года в Калифорнийском университете в Лос-Анджелесе.
					Сейчас центры Хабада существуют по всему миру. Каждый из них объединяет местную еврейскую общину.
					Центр Хабада проводит образовательные и просветительские мероприятия, причем, для всех евреев,
					вне зависимости от степени соблюдения ими обычаев иудаизма.
					Также деятельность центра Хабада направлена на поддержание традиций в общине, на помощь нуждающимся.
					Каждый центр стремится создать уютную и неформальную площадку для встреч.
					Многие центры находятся в студенческих городках.
				</p>

				<p className={styles.paragraph}>
					Дома Хабада в университетах, известные как «Хабад на кампусе», часто предоставляют жилье для студентов,
					имеют в своей структуре профилактические центры по предотвращению наркозависимости, офисы студенческой деятельности,
					синагогу, библиотеку, кошерную столовую, комнаты отдыха и компьютерный зал.
				</p>
			</div>}

			{props.isEnglish && <div className='wrapper_static'>
				<h1 className={styles.chabad__title}>Chabad House</h1>

				<p className={styles.paragraph}>
					A Chabad House (in Hebrew – The House of Chabad) is a centre for disseminating Hasidic Judaism by the Chabad movement.
					The word «Chabad»  is made up of three words in Hebrew. The word חוכמה [khokma] («Wisdom») begins with «ח».
					The word בינה [bina] («Comprehension»/«Insight») begins with « ה». The word דעת [daat] («Cognition»/ «Knowledge») begins with «ת». 
				</p>

				<p className={styles.paragraph}>The House of Chabad is headed by the Chabad's envoy – shaliach (שליח).</p>

				<p className={styles.paragraph}>
					The first Chabad Houses were opened in the USA in the second half of the 20th century. Menachem Mendel Schneerson,
					third Rebbe of the Chabad Lubavitch dynasty, sent his envoy, Rabbi Shlomo Cunin, to Los Angeles in 1965.
					The first Chabad House for university students was opened in March 1969 at the University of California, Los Angeles.
					Nowadays Chabad Houses work all over the world. Each Chabad House unites a local Jewish community.
					Chabad House realizes educational events for all Jews, regardless of how much they observe the customs of Judaism.
					Chabad House also tries to keep traditions in the community and help those who need help.
					Each Chabad House tries to create a cozy and informal meeting place. Many Chabad Houses are located on student campuses. 
				</p>

				<p className={styles.paragraph}>
					Chabad Houses on campuses often give students accommodation.
					There are centers in the structure of Chabad Houses on campuses that prevent drug addiction.
					There are also student activity offices, synagogues, libraries, kosher canteens, recreation rooms, computer rooms. 
				</p>
			</div>}
		</div>
  )
}