import { useMapStore } from '../../../store'
import { SynagogueType } from '../../../types/synagogue-type'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './SynagogueDescription.module.css'

export const SynagogueDescription = () => {
  const synagogoguesList = useMapStore((state) => state.synagoguesList)
  const [selectedSynagogueData, setSelectedSynagigueData] = useState<SynagogueType>()
  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false)
  const selectedId = useMapStore((state) => state.selectedId)
  const resetSelectedId = useMapStore((state) => state.resetSelectedId)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const [selectedButtonIndex, setSelectedButtonIndex] = useState<number | null>(null)
  const [activeContactQr, setActiveContactQr] = useState<string>('')

  useEffect(() => {
    if (selectedId && synagogoguesList) {
      synagogoguesList.forEach((synagogue) => {
        if (synagogue.id === selectedId) {
          setSelectedSynagigueData(synagogue)
        }
      })
    }
  }, [selectedId, synagogoguesList])

  return (
    <>
      {selectedSynagogueData && <div className={styles.wrapper} onClick={hideKeyboard}>
        <button className={styles.back__btn} type='button' onClick={() => resetSelectedId()}>
          <img
            className={styles.back__icon}
            src="/icons/arrow.svg"
            alt="Стрелка назад"
          />
          НАЗАД
        </button>

        <div className={styles.title__block}>
          <h2 className={styles.title}>{selectedSynagogueData.title}</h2>

          <div className={styles.description__block}>
            {!isDescriptionOpened && <p className={styles.description}>{selectedSynagogueData.short_description}</p>}
          
            {isDescriptionOpened && <>
              {selectedSynagogueData.full_description.map((text) => {
                return <p
                key={selectedSynagogueData.full_description.indexOf(text)}
                className={styles.description}>
                  {text}
                </p>
              })}
            </>}

            <button
              className={styles.read__full__btn}
              onClick={() => setIsDescriptionOpened(!isDescriptionOpened)}
              type='button'>
                {!isDescriptionOpened ? 'Читать полностью' : 'Скрыть'}
            </button>
          </div>
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Раввин</span>
          <span className={styles.block__info}>{selectedSynagogueData.rabbi_name}</span>
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Локация</span>
          <span className={styles.block__info}>{selectedSynagogueData.location}</span>
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Телефон</span>
          {selectedSynagogueData.phone.map((number) => {
            return <span key={selectedSynagogueData.phone.indexOf(number)} className={styles.block__info}>
              {number}
            </span>
          })}
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Контакты</span>
          <div className={styles.contacts__btns__block}>
            {selectedSynagogueData.contacts.map((contact) => {
              return <button
                className={cn(styles.contact__btn,
                  {
                    [styles.contact__btn_active] : (selectedButtonIndex === null && selectedSynagogueData.contacts.indexOf(contact) === 0) || (selectedButtonIndex !== null && selectedSynagogueData.contacts.indexOf(contact) === selectedButtonIndex),
                    [styles.first__btn]: selectedSynagogueData.contacts.indexOf(contact) === 0
                  })
                }
                key={selectedSynagogueData.contacts.indexOf(contact)}
                type='button'
                onClick={() => {
                  setSelectedButtonIndex(selectedSynagogueData.contacts.indexOf(contact))
                  setActiveContactQr(contact.qr)
                }}
              >
                <img alt="Иконка контакта" src={contact.image} />
              </button>
            })}

            <div className={styles.qr__block}>
              <span>
                {!selectedButtonIndex ? 'Общее' : selectedSynagogueData.contacts[selectedButtonIndex].title}
              </span>

              <img
                alt='Qr-код контакта синагоги'
                src={!selectedButtonIndex ? selectedSynagogueData.contacts[0].qr : activeContactQr}
                style={{
                  width: '120px',
                  height: '120px'
                }}
              />
            </div>
          </div>
          {selectedButtonIndex &&
            (selectedSynagogueData.contacts[selectedButtonIndex].title === 'instagram' ||
            selectedSynagogueData.contacts[selectedButtonIndex].title === 'facebook') ?
            <span className={styles.balderdash}>
              *Проект Meta Platforms Inc., деятельность которой запрещена в России
            </span> :
            null
          }
        </div>

        {selectedSynagogueData.gallery && selectedSynagogueData.gallery.length > 0 &&
          <Link to={`/map?synagogue=${selectedId}`}>
          клик
        </Link>}
      </div>}
    </>
  )
}