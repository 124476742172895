import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from 'react-router-dom'

export const InternetConnectionChecker = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true))
    window.addEventListener('offline', () => setIsOnline(false))
    return () => {
      window.removeEventListener('online', () => setIsOnline(true))
      window.removeEventListener('offline', () => setIsOnline(false))
    }
  }, [])

  useEffect(() => {
    if (!isOnline && pathname !== '/no-internet') {
      navigate('/no-internet')
    }

    if (isOnline && pathname === '/no-internet') {
      navigate('/map')
    }
  }, [isOnline, navigate, pathname])
  
  return (
    <div />
  )
}