import { useState } from 'react';
import { useMapStore } from '../../../store';
import Keyboard from 'react-simple-keyboard';
import { formatAddress } from '../../../helpers/format-adress';
import { GeoObjectType } from '../../../types/geo-object-type';

export const TouchKeyboard = () => {
  // const [adress, setAdress] = useState('')
  const [showNumbersRow, setShowNumbersRow] = useState(false)

  const adress = useMapStore((state) => state.adress)
  const setAdress = useMapStore((state) => state.setAdress)
  // const setCoordinates = useMapStore((state) => state.setSearchedCoordinates)
  const isKeyboardVisible = useMapStore((state) => state.isKeyboardVisible)
  const setGeoObjectsList = useMapStore((state) => state.setGeoObjectsList)

  const sendAdress = async () => {
    if (adress && adress.length > 0) {
      const request = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=137fab3c-04c3-472f-b81a-08f047d6e2dc&geocode=${adress}&lang=ru_RU&format=json`)
      const response = await request.json()
      const listOfCities = response.response.GeoObjectCollection.featureMember
      const filteredListOfCities: GeoObjectType[] = []
      if (listOfCities.length > 0) {
        listOfCities.forEach((element: any) => {
          if (element && (element.GeoObject.description.includes('Московская область') || element.GeoObject.description.includes('Москва'))) {
            const formatedAdress = formatAddress(element.GeoObject.description.replace('Россия', ''), element.GeoObject.name)
            filteredListOfCities.push({
              adress: formatedAdress,
              coordinates: element.GeoObject.Point.pos.split(' ').map(Number)
            })
          }
        })
      }

      if (filteredListOfCities.length > 0) {
        setGeoObjectsList(filteredListOfCities)
      } else {
        setGeoObjectsList([{adress: null, coordinates: []}])
      }
    }
  }
  
  const buttonTheme = [
    {
      class: "keyboard-button",
      buttons: "1 2 3 4 5 6 7 8 9 0 й ц у к е н г ш щ з х ъ ф ы в а п р о л д ж э {shift} я ч с м и т ь б ю . , ?123 {bksp} {enter} "
    },
    {
      class: "custom-bksp",
      buttons: "{bksp}"
    },
    {
      class: "custom-shift",
      buttons: "{shift}"
    },
    {
      class: "custom-space",
      buttons: "{space}"
    },
    {
      class: "custom-enter",
      buttons: "{enter}"
    }
  ];

  return <>
    {isKeyboardVisible && <Keyboard
      onChange={(e) => setAdress(e)}
      layout={
        showNumbersRow ?
        {
          default: [
            "1 2 3 4 5 6 7 8 9 0",
            "й ц у к е н г ш щ з х ъ",
            "ф ы в а п р о л д ж э",
            "{shift} я ч с м и т ь б ю , . {bksp}",
            "{space}"
          ],
        }
        :
        {
          default: [
            "1 2 3 4 5 6 7 8 9 0",
            "й ц у к е н г ш щ з х ъ",
            "ф ы в а п р о л д ж э",
            "{shift} я ч с м и т ь б ю {bksp}",
            "?123 , {space} . {enter}"
          ],
        }
       }
      buttonTheme={buttonTheme}
      onKeyPress={(key) => {
        if (key === '?123') {
          setShowNumbersRow(!showNumbersRow)
        }

        if (key === '{enter}') {
          sendAdress()
        }
      }}
    />}
  </>
}