import cn from 'classnames'
import { Search } from '../Search'
import { SynagogueList } from '../SynagogueList'
import { useMapStore } from '../../../store'
import { SynagogueDescription } from '../SynagogueDescription'
import { SelectedAdress } from '../SelectedAdress'
import styles from './Sidebar.module.css'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const Sidebar = () => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(60);

  const resetSelectedId = useMapStore((state) => state.resetSelectedId)
  const resetSearchedCoordinates = useMapStore((state) => state.resetSearchedCoordinates)
  const resetAdress = useMapStore((state) => state.resetAdress)
  const resetGeoObjectsList = useMapStore((state) => state.resetGeoObjectsList)
  const setSynagoguesList = useMapStore((state) => state.setSynagoguesList)

  useEffect(() => {
    let timer

    timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1)
    }, 1000)

    if (countdown === 0) {
      clearInterval(timer)
      resetSelectedId()
      resetSearchedCoordinates()
      resetAdress()
      resetGeoObjectsList()
      setSynagoguesList([])
      navigate('/')
    }

    return () => clearInterval(timer)
  }, [countdown, navigate, resetAdress, resetGeoObjectsList, resetSearchedCoordinates, resetSelectedId, setSynagoguesList])
  
  const selectedId = useMapStore((state) => state.selectedId)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const adress = useMapStore((state) => state.adress)
  const geoObjectsList = useMapStore((state) => state.geoObjectsList)
  const synagogoguesList = useMapStore((state) => state.synagoguesList)

  return (
    <aside className={cn({
      [styles.wrapper]: !selectedId,
      [styles.wrapper_active]: selectedId,
    })}
    onClick={() => setCountdown(60)}>
      <img className={styles.logo} src="/icons/sidebar-header.svg" alt="Лого музея" onClick={hideKeyboard} />
      {!selectedId && geoObjectsList.length === 0 && <Search />}
      {!selectedId && geoObjectsList.length > 0 && adress && adress.length > 0 &&
        <SelectedAdress adress={adress}
      />}

      {
        !selectedId && geoObjectsList.length === 0 && <SynagogueList isSynagoguesList synagogoguesList={synagogoguesList} onClick={hideKeyboard} />
      }

      {
        selectedId && <SynagogueDescription />
      }

      {
        geoObjectsList.length > 0 && geoObjectsList[0].adress && !selectedId &&
        <SynagogueList isGeoObjectList geoObjectsList={geoObjectsList} onClick={hideKeyboard} />
      }

      
      {     
        geoObjectsList.length === 1 && !geoObjectsList[0].adress &&
        <SynagogueList isError />
      }
    </aside>
  )
}