import styles from './Overlay.module.css'

export const Overlay = (props: {
  onClick: () => void
}) => {
  return (
    <div className={styles.overlay} onClick={props.onClick}>
      <img className={styles.hand_first} src="/icons/hand.svg" alt="Рука" />
      <img className={styles.hand_second} src="/icons/hand.svg" alt="Рука" />
      <img className={styles.hand_third} src="/icons/hand.svg" alt="Рука" />
    </div>
  )
}