import { create } from 'zustand'
import { SynagogueType } from './types/synagogue-type'
import { GeoObjectType } from './types/geo-object-type'

type MapStoreType = {
  geoObjectsList: GeoObjectType[]
  synagoguesList: SynagogueType[]
  selectedId: number | null,
  searchedCoodrinates: number[],
  setSelectedId: (newSelectedId: number) => void
  setSearchedCoordinates: (newSearchedCoodrinates: number[]) => void
  resetSearchedCoordinates: () => void
  resetSelectedId: () => void
  isKeyboardVisible: boolean
  setIsKeyboardVisible: () => void
  setIsKeyboardHidden: () => void
  adress: string | null
  setAdress: (newAdress: string) => void
  resetAdress: () => void
  setSynagoguesList: (data: SynagogueType[]) => void
  setGeoObjectsList: (data: any[]) => void
  resetGeoObjectsList: () => void
}

export const useMapStore = create<MapStoreType>((set) => ({
  geoObjectsList: [],
  synagoguesList: [],
  selectedId: null,
  searchedCoodrinates: [],
  isKeyboardVisible: false,
  adress: null,
  setAdress: (newAdress: string) => set(() => ({ adress: newAdress })),
  setIsKeyboardVisible: () => set(() => ({ isKeyboardVisible: true })),
  setIsKeyboardHidden: () => set(() => ({ isKeyboardVisible: false })),
  setSelectedId: (newSelectedId: number) => set(() => ({ selectedId: newSelectedId })),
  setSearchedCoordinates: (newSearchedCoodrinates: number[]) => set(() => ({ searchedCoodrinates: newSearchedCoodrinates })),
  resetSelectedId: () => set(() => ({ selectedId: null })),
  resetAdress: () => set(() => ({ adress: null })),
  resetSearchedCoordinates: () => set(() => ({ searchedCoodrinates: [] })),
  setSynagoguesList: (data: SynagogueType[]) => set(() => ({ synagoguesList: data })),
  setGeoObjectsList: (data: GeoObjectType[]) => set(() => ({ geoObjectsList: data })),
  resetGeoObjectsList: () => set(() => ({ geoObjectsList: [] }))
}))
