import mapStyles from '../../../pages/Map/customization.json'

import {
  YMap,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapComponentsProvider
} from 'ymap3-components'
import { LngLat } from '@yandex/ymaps3-types'
import { useEffect, useRef, useState } from 'react'
import styles from './YandexMap.module.css'

export const YandexMap = (props: {
  center: LngLat,
  children: any[] | undefined
}) => {
  const mapRef= useRef(null)
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  useEffect(() => {
    if (mapRef) {
      setTimeout(() => {
        setIsMapLoaded(true)
      }, 3100)
    }
  }, [])

  return (
    <div className={isMapLoaded ? styles.wrapper_active : styles.wrapper_inactive}>
      <YMapComponentsProvider apiKey='137fab3c-04c3-472f-b81a-08f047d6e2dc'>
        <YMap location={{center: props.center, zoom: 12}} copyrightsPosition='bottom left' zoomRange={{min: 12, max: 20}} ref={mapRef}>
          <YMapDefaultSchemeLayer customization={mapStyles as any} />
          <YMapDefaultFeaturesLayer />
          {props.children}
        </YMap>
      </YMapComponentsProvider>
    </div>
  )
}