import { JsonEditor } from 'json-edit-react'
import { useCallback, useEffect, useState } from "react"
import styles from './JSONEditor.module.css'

export function JSONEditor() {
  const [json, setJSON] = useState([])

  const getJSON = useCallback(async () => {
    const json = await fetch('/synagogues.json').then(r => r.json())
    setJSON(json)
  }, [])

  useEffect(() => {
    getJSON()
  }, [getJSON])

  const downloadJSON = () => {
    const jsonData = JSON.stringify({ ...json })
    const blob = new Blob([jsonData], { type: 'application/json' })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `synagogues.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.editor__block}>
        <JsonEditor
          theme={'githubLight'}
          data={json}
          setData={(data) => setJSON(data as any)}
          maxWidth={'100vw'}
          collapse
        />

        <ul className={styles.rules}>
          <li className={styles.rule}>
            short_description — раздел в карточке синагоги, заканчивающийся многоточием.
          </li>

          <li className={styles.rule}>
            full_description — раздел в карточке синагоги с полным её описанием, разбитый на абзацы.
            Пример заполнения полного описания с несколькими абзацами можно увидеть в объекте с id 13.
          </li>

          <li className={styles.rule}>
            id — уникальный идентификатор.
          </li>

          <li className={styles.rule}>
            contacts — ссылка на qr-код должна быть вида<br/>'/название.png'.
          </li>

          <li className={styles.rule}>
            Название сохраняемого json-файла нельзя изменять. После сохранения файл нужно поместить в папку build.
          </li>
        </ul>
      </div>

      <button
        className={styles.download_btn}
        type='button'
        onClick={downloadJSON}
      >
        Скачать JSON
      </button>
    </div>
  )
}
