import { useEffect, useState } from 'react'
import 'react-simple-keyboard/build/css/index.css'
import { Overlay } from '../components/layout/Overlay'
import { AnimatedText } from '../components/ui/AnimatedText'
import { ScreensaverOverlay } from '../components/layout/ScreensaverOverlay'
import { Link } from 'react-router-dom'
import { StaticText } from '../components/ui/StaticText'
import { InternetConnectionChecker } from '../components/layout/InternetConnectionChecker'
import Lottie from 'react-lottie'
import lottieRu from '../lotties/ru.json'
import lottieEng from '../lotties/eng.json'
import './App.css'

export function App() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true)
  const [isScreenSaverVisible, setIsScreenSaverVisible] = useState(false)
  const [overlayCountdown, setOverlayCountdown] = useState(6)
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [isAnimatedTextClicked, setIsAnimatedTextClicked] = useState(false)

  const defaultOptionsRu = {
    loop: false,
    autoplay: false,
    animationData: lottieEng,
    className: 'switch-language-btn'
  }

  const defaultOptionsEng = {
    loop: false,
    autoplay: false,
    animationData: lottieRu,
    className: 'switch-language-btn'
  }

  useEffect(() => {
    let timer

    timer = setInterval(() => {
      setOverlayCountdown(prevCountdown => prevCountdown - 1)
    }, 10000)

    if (overlayCountdown === 0 && !isOverlayVisible) {
      setIsOverlayVisible(true)
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [isOverlayVisible, overlayCountdown])

  useEffect(() => {
    if (!isScreenSaverVisible) {
      if (selectedLanguage === 'ru') {
          setTimeout(() => {
            setIsScreenSaverVisible(true)
          }, 84000);
        }
      } else {
        if (selectedLanguage === 'eng') {
          setTimeout(() => {
            setIsScreenSaverVisible(true)
          }, 85500);
        }
      }

    if (isScreenSaverVisible) {
      setTimeout(() => {
        setIsScreenSaverVisible(false)
      }, 2000);
    }
  }, [isScreenSaverVisible, isAnimatedTextClicked, selectedLanguage])

  useEffect(() => {
    setOverlayCountdown(6)
    if (isAnimatedTextClicked) {
      setTimeout(() => {
        setIsAnimatedTextClicked(false)
      }, 10000)
    }
  }, [isAnimatedTextClicked])

  const handleLanguageChange = () => {
    if (selectedLanguage === '' || selectedLanguage === 'ru') {
      setTimeout(() => {
        setSelectedLanguage('eng')
      }, 500)
    }

    if (selectedLanguage === 'eng') {
      setTimeout(() => {
        setSelectedLanguage('ru')
      }, 500)
    }
  }

  return (
    <main className='app'>
      <InternetConnectionChecker />

      {isOverlayVisible && <Overlay onClick={() => setIsOverlayVisible(false)} />}
      {isScreenSaverVisible && <ScreensaverOverlay />}

      {(selectedLanguage === 'ru' || selectedLanguage === '') &&
        !isAnimatedTextClicked && <AnimatedText isRussian onClick={() => {
          setIsAnimatedTextClicked(true)
          setOverlayCountdown(6)
        }}
      />}

      {(selectedLanguage === 'ru' || selectedLanguage === '') &&
        isAnimatedTextClicked && <StaticText isRussian
      />}

      {selectedLanguage === 'eng' && !isAnimatedTextClicked &&
        <AnimatedText isEnglish onClick={() => {
          setIsAnimatedTextClicked(true)
          setOverlayCountdown(6)
        }}
      />}

      {selectedLanguage === 'eng' && isAnimatedTextClicked &&
        <StaticText isEnglish
      />}

      <div className='buttons-block'>
        <Link to={'/map'} className='button'>
          <img src='/icons/map-icon.svg' alt="Иконка карты" />
          {(selectedLanguage === 'ru' || selectedLanguage === '') ?
            'Поиск ближайшего Бейт Хабада' :
            'Find the nearest Chabad House'
          }
        </Link>

        <button className='switch-language-btn' onClick={handleLanguageChange}>
          {(selectedLanguage === 'ru' || selectedLanguage === '') && <Lottie 
            options={defaultOptionsRu}
            isStopped={isOverlayVisible || selectedLanguage === '' || selectedLanguage === 'ru'}
          />}

          {selectedLanguage === 'eng' && <Lottie 
            options={defaultOptionsEng}
            isStopped={isOverlayVisible || selectedLanguage === 'eng'}
          />}
        </button>
      </div>
    </main>
  )
}
