import { useMapStore } from '../../../store'
import styles from './SelectedAdress.module.css'

export const SelectedAdress = (props: {
  adress: string
}) => {
  const resetAdress = useMapStore((state) => state.resetAdress)
  const resetCoordinates = useMapStore((state) => state.resetSearchedCoordinates)
  const resetGeoObjectsList = useMapStore((state) => state.resetGeoObjectsList) 

  return (
    <div className={styles.wrapper}>
      <div className={styles.title__block}>
        <span className={styles.title}>Адрес</span>
        <p className={styles.adress}>{props.adress}</p>
      </div>

      <button className={styles.change__btn} onClick={() => {
        resetAdress()
        resetCoordinates()
        resetGeoObjectsList()
      }}>
        Изменить
      </button>
    </div>
  )
}