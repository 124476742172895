import styles from './Docs.module.css'

export function Docs() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2>Общая информация</h2>
        <h3>Сайт состоит из пяти страниц</h3>
        <ol>
          <li>Главная страница с заставкой: <i>/</i></li>
          <li>Страница с картой: <i>/map</i></li>
          <li>Страница с редактированием списка синагог: <i>/editor</i></li>
          <li>Страница с документацией: <i>/docs</i></li>
          <li>Страница, которая видна только при осутствии интернета: <i>/no-internet</i></li>
        </ol>

        <p>
          Первые две страницы доступны по находящимся на них ссылкам (или автоматическому редиректу).
          Оставшиеся страницы доступны только по прямой ссылке.
        </p>
      </div>
      
      <div>
        <h2>Редактирование JSON со списком синагог</h2>
        <p>
          На странице <i>/editor</i> доступны несколько способов редактирования JSON-файла со списком синагог:
          <ol>
            <li>
              Редактирование файла напрямую. Для этого необходимо раскрыть список, нажав на кнопку <i>root</i>
              и нажать на иконку редактирования в появившемся справа списке. Этот способ удобен тем, что вы
              можете, подключив клавиатуру и мышь, создавать новые объекты в массиве, ориентируясь на созданные ранее.
            </li>

            <li>
              Создание новых объектов через интерфейс редактора. Для этого нужно нажать на кнопку root и выбрать в появившемся
              списке опцию добавления нового объекта (иконка +).
            </li>
          </ol>

          <h3>Основные правила создания новых объектов синагог:</h3>
          <ul>
            <li>Нельзя менять <i>id</i>, новый <i>id</i> должен быть уникальным.</li>
            <li>
              Нельзя добавлять новые поля, которых нет в предыдущих объектах.
              В противном случае эти поля не будут отображаться на сайте.
            </li>
            <li>Раздел <i>short_description</i> — раздел в карточке синагоги, заканчивающийся многоточием</li>
            <li>
              Раздел <i>full_description</i> — раздел в карточке синагоги с полным её описанием, разбитый на абзацы.
              Пример заполнения полного описания с несколькими абзацами можно увидеть в объекте с id 13.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h2>Изменение API-ключей</h2>
        <p>
          Напрямую в папке <i>build</i> изменить ключи не получится, для этого необходимо работать с репозиторием напрямую.

        </p>

        <p>
          В проекте используется один API-ключ, но от двух разных сервисов Яндекса: Геокодер и Яндекс-карты.<br/>
          Первый можно заменить на 19 строке файла <i>src/components/ui/TouchKeyboard/index.tsx.</i><br/>
          Второй — на строке 18 файла <i>ssrc/components/layout/YandexMap/index.tsx.</i>
        </p>
      </div>
    </div>
  )
}
