import { InternetConnectionChecker } from '../../components/layout/InternetConnectionChecker'
import styles from './NoEnternet.module.css'

export function NoEnternet() {
  return (
    <div className={styles.wrapper}>
      <InternetConnectionChecker />
      <div className={styles.title__block}>
        <h1 className={styles.title}>
          Нет подключения<br/>
          к&nbsp;интернету
        </h1>

        <p className={styles.description}>
          Невозможно подключиться.<br/>
          При наличии доступной сети, подключение произойдёт автоматически
        </p>
      </div>

      <img
        alt="Нет интернета"
        className={styles.icon}
        src="/icons/no-internet.svg"
      />
    </div>
  )
}