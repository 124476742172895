import { useCallback, useEffect, useRef } from 'react';
import { useMapStore } from '../../../store'
import { TouchKeyboard } from '../TouchKeyboard';
import styles from './Search.module.css'

export const Search = React.forwardRef(() => {
  const adress = useMapStore((state) => state.adress)
  const isKeyboardVisible = useMapStore((state) => state.isKeyboardVisible)
  const setIsKeyboardVisible = useMapStore((state) => state.setIsKeyboardVisible)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleBlur = useCallback(() => {
    if (inputRef.current && isKeyboardVisible) {
      inputRef.current.focus()
    } 
    
    if (inputRef.current && !isKeyboardVisible) {
      inputRef.current.blur()
    }
  }, [isKeyboardVisible])

  useEffect(() => {
    handleBlur()
  }, [handleBlur])
  
  return <div className={styles.wrapper}>
    <div className={styles.input__wrapper}>
      <label className={styles.title}>Поиск</label>
      <input
        ref={inputRef}
        className={styles.input}
        type="text"
        placeholder="введите город и улицу"
        value={(adress !== null && adress.length > 0) ? adress : ''}
        onFocus={setIsKeyboardVisible}
        readOnly
      />
      <img className={styles.map__pin} src="/icons/map-pin.svg" alt="Метка" />
      {/* <img className={styles.search__icon} src="/search-icon.svg" alt="лупа" /> */}
    </div>

    <TouchKeyboard />
  </div>
})