import { SynagogueListElement } from '../SynagogueListElement'
import { useMapStore } from '../../../store'
import { SynagogueType } from '../../../types/synagogue-type'
import styles from './SynagogueList.module.css'
import { GeoObjectType } from '../../../types/geo-object-type'

export const SynagogueList = (props: {
  synagogoguesList?: SynagogueType[]
  geoObjectsList?: GeoObjectType[]
  onClick?: () => void
  isSynagoguesList?: boolean
  isGeoObjectList?: boolean
  isError?: boolean
}) => {
  const setSelectedId = useMapStore((state) => state.setSelectedId)
  const setSearchedCoordinates = useMapStore((state) => state.setSearchedCoordinates)

  return (
    <div className={styles.wrapper} onClick={props.onClick}>
      <h2 className={styles.list__title}>
        {props.isSynagoguesList && 'Список Бейд Хабад'}
        {props.isGeoObjectList && 'Список адресов'}
        {props.isError && 'Ошибка'}
      </h2>

      {props.isSynagoguesList && props.synagogoguesList && <>
        <div>
          {props.synagogoguesList && props.synagogoguesList.map((synagogue) => {
            return <SynagogueListElement
            key={synagogue.id}
            text={synagogue.title}
            onClick={() => setSelectedId(synagogue.id)}
          />})}
        </div>
      </>}
      
      {props.isGeoObjectList && props.geoObjectsList && props.geoObjectsList.length > 0 && <>
        <div>
          {props.geoObjectsList.map((object) => {
            return <SynagogueListElement
            key={props.geoObjectsList && props.geoObjectsList.indexOf(object)}
            text={object.adress}
            onClick={() => setSearchedCoordinates(object.coordinates)}
          />})}
        </div>
      </>}

      {props.isError && <p className={styles.error}>
        Пожалуйста, введите адрес, находящийся в Москве или Московской области
      </p>}
    </div>
  )
}