import { Sidebar } from '../../components/ui/SIdebar'
import {  useCallback, useEffect, useState } from 'react'
import { useMapStore } from '../../store'
import { YandexMap } from '../../components/layout/YandexMap'
import { LngLat } from '@yandex/ymaps3-types'
import { YMapMarker } from 'ymap3-components'
import { shuffleArray } from '../../helpers/shuffle-array'
import { Gallerymodal } from '../../components/layout/GalleryModal'
import { useSearchParams } from 'react-router-dom'
import { InternetConnectionChecker } from '../../components/layout/InternetConnectionChecker'
import styles from './Map.module.css'

export function Map() {
  const [params] = useSearchParams()
  const synagogue = params.get('synagogue')

  const idFromStore = useMapStore((state) => state.selectedId)
  const coordinates = useMapStore((state) => state.searchedCoodrinates)
  const synagogoguesList = useMapStore((state) => state.synagoguesList)
  const setSynagoguesList = useMapStore((state) => state.setSynagoguesList)
  const searchedCoodrinates = useMapStore((state) => state.searchedCoodrinates)
  const selectedId = useMapStore((state) => state.selectedId)
  const setSelectedId = useMapStore((state) => state.setSelectedId)
  const [defaultCenter, setDefaultCenter] = useState<LngLat>([37.622244, 55.752708])

  useEffect(() => {
    if (coordinates && coordinates.length > 0) {
      setDefaultCenter([coordinates[0], coordinates[1]] as LngLat)
    }

    if (idFromStore) {
      synagogoguesList.forEach((synagogue) => {
        if (synagogue.id === idFromStore) {
          setDefaultCenter(synagogue.coordinates as LngLat)
        }
      })
    }
  }, [coordinates, idFromStore, synagogoguesList])

  const getSynagogues = useCallback( async () => {
    const synagoguesList = await fetch('/synagogues.json').then(r => r.json())
    const shuffledArray = shuffleArray(synagoguesList)
    setSynagoguesList(shuffledArray)
  }, [setSynagoguesList])

  useEffect(() => {
    getSynagogues()
  }, [getSynagogues])
  
  return (
    <main className={styles.page__wrapper}>
      <InternetConnectionChecker />
      <Sidebar />
      <YandexMap center={defaultCenter as LngLat}>
        {synagogoguesList && synagogoguesList.map((synagogue) => {
          if (synagogue.id !== 10) {
            return <YMapMarker coordinates={synagogue.coordinates as LngLat} key={synagogue.id}>
              <div className={styles.marker}>
                <img
                  alt='Иконка синагоги'
                  src={selectedId === synagogue.id ? '/icons/synagogue_active.svg' : '/icons/synagogue_default.svg'}
                  style={{width: '70px', height: '70px'}}
                  onClick={() => {
                    setSelectedId(synagogue.id)
                    setDefaultCenter(synagogue.coordinates as LngLat)
                  }}
                />
                <span className={selectedId === synagogue.id ? styles.synagogue__name_active : styles.synagogue__name_default}>
                  {synagogue.title}
                </span>
              </div>
            </YMapMarker>
          } else {
            return null
          }
        })}
        {searchedCoodrinates && searchedCoodrinates.length > 0 && <YMapMarker
          coordinates={searchedCoodrinates as LngLat}
        >
          <img alt="" src='/icons/map-pin.svg' style={{width: '70px', height: '70px'}} />
        </YMapMarker>}
      </YandexMap>
      {synagogue && <Gallerymodal selectedSynagogueId={synagogue} />}
    </main>
  )
}